import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import Cookies from 'universal-cookie'

import headerStyles from "./styles/header.module.scss"

const cookies = new Cookies();

const Links = [
  {
    name: "About",
    to: "/about",
    key:1
  },
  {
    name: "Archive",
    to: "/archive",
    key:2
  },
  {
    name: "Projects",
    to: "/projects",
    key:3
  },
  {
    name: "Theme",
    to: "/",
    type: "theme",
    key:4
  }
]

const themeHanlder = (e) => {
  e.preventDefault()
  var theme = cookies.get('theme');
  if(theme === "light" || theme === undefined){
    cookies.set('theme','dark',{path:'/'});
  }else{
    cookies.set('theme','light',{path:'/'});
  }
  themeChecker();
}

const themeChecker = (e) => {
  var theme = cookies.get('theme');
  var body = document.body;
  if(theme === 'light' || theme === undefined){
    body.classList.remove("dark-theme")
  }else{
    body.classList.add("dark-theme");
  }
}

const linksRendered = Links.map((link)=>{
  if(link.type === "theme"){
    return(
      <li key={link.key.toString()}>
      <a role="button"
      className={headerStyles.link} onClick={(e)=> themeHanlder(e)}>
      {link.name}
      </a>
      </li>
    )
  }
  return (
    <li key={link.key}>
      <Link to={link.to} className={headerStyles.link}>
        {link.name}
      </Link>
    </li>
  )
})


const Header = ({ siteTitle, isIndex }) => {
  useEffect(()=>{
    themeChecker();  
  });
  return (
  <header className={headerStyles.header}>
    <nav className={headerStyles.nav, "container"}>
      <div>
        <Link to="/">
        <h1 className={headerStyles.title}>
          Simon's BlogChain
        </h1>
        </Link>
    </div>
      <ul className={headerStyles.navList}>
        {linksRendered}
      </ul>
    </nav>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  isIndex: PropTypes.bool
}

Header.defaultProps = {
  siteTitle: `Technical`,
  isIndex:false
}

export default Header
