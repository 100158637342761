import React from "react"

import footerStyles from "./styles/footer.module.scss"


import linkedinGeneric from "../images/linkedin_generic.png";
import twitterGeneric from "../images/twitter_generic.png";
import mindsGeneric from "../images/minds_generic.png";

const Separator = () =>{
  return(
      <li className={footerStyles.separator}>
        |
      </li>
  )
}


const Footer = ({ siteTitle, isIndex }) => {
  
  return (
    <footer>
    <ul>
      <li>
      &copy; 2019
      </li>
      <Separator />
      <li>
      <a target="_blank" rel="noopener noreferrer" 
        href="https://twitter.com/SSekavcnik">
      Simon Sekavcnik
      </a>
      </li>
      <Separator />
      <li>
        <div className={footerStyles.social}>
        <a target="_blank" rel="noopener noreferrer" 
          href="https://twitter.com/SSekavcnik">
          <img alt="social-icon-twitter" src={twitterGeneric} />
        </a>
        <a className={footerStyles.minds}
          target="_blank" rel="noopener noreferrer" 
          href="https://twitter.com/SSekavcnik">
          <img alt="social-icon-minds" src={mindsGeneric} />
        </a>
        <a target="_blank" rel="noopener noreferrer" 
          href="https://twitter.com/SSekavcnik">
          <img alt="social-icon-linkedin" src={linkedinGeneric} />
        </a>
        </div>
      </li>
      <Separator />
      <li>
      <a href="https://creativecommons.org/licenses/by-nc/4.0/">CC BY_NC 4.0</a>
      </li>
    </ul>
    </footer>
  )
}


export default Footer 
