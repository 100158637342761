import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent";
import { CookiesProvider } from 'react-cookie'


import "./styles/global.scss"
import layoutStyles from "./styles/layout.module.scss"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children, isIndex }) => {
  return (
    <CookiesProvider>
    <div className={layoutStyles.root}>
        <Header></Header>
        <CookieConsent>
        This website uses cookies to enhance the user experience.
        </CookieConsent>
        <div className={layoutStyles.content, "container"}>
        {children}
        </div>
        <Footer></Footer>
    </div>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isIndex: PropTypes.bool
}

export default Layout
